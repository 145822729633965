<template>
  <Orders />
</template>
<script>
  import Orders from "@/components/Orders";
  export default {
    components: {
      Orders,
    },
  };
</script>
