<template>
  <div>
    <div class="order scrollbar">
      <div v-if="loading">
        <div v-for="index in 3" :key="index">
          <Skeleton width="10rem" height="1.2rem" class="order-time mt-10" />
          <Skeleton width="15rem" height="1.2rem" />
        </div>
        <Skeleton width="10rem" height="3rem" class="mt-12" />
      </div>
      <div v-else>
        <div>
          <p class="text-sm md:font-semibold text-lightPurple-2">
            Order #{{ orderDetail.orderCode }}
          </p>
        </div>
        <div class="">
          <div class="order-time mt-10">
            <p class="text-sm text-black md:mb-1">Transaction</p>
            <h3 class="text-black md:text-lg font-semibold">
              <span
                :class="{
                  pending: orderDetail.status === 'PENDING',
                  closed: orderDetail.status === 'CLOSED',
                  success: orderDetail.status === 'SUCCESSFUL',
                  processing: orderDetail.status === 'PAYMENT_IN_PROGRESS',
                  failed: orderDetail.status === 'FAILED',
                  refunded: orderDetail.status === 'REFUNDED'
                }"
                >{{ orderDetail.status | capitalize }}</span
              >
            </h3>
          </div>
          <div
            class="mt-4"
            v-if="orderDetail.customerFirstName || orderDetail.customerLastName"
          >
            <p class="text-sm text-black md:mb-1">Customer name</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ orderDetail.customerFirstName | capitalize }}
              {{ orderDetail.customerLastName | capitalize }}
            </h3>
          </div>
          <div class="mt-4" v-else-if="orderDetail.anonymous === true">
            <p class="text-sm text-black md:mb-1">Customer</p>
            <h3 class="text-black md:text-lg font-semibold">
              Anonymous user
            </h3>
          </div>
          <div class="mt-4" v-if="orderDetail.customerEmailAddress">
            <p class="text-sm text-black md:mb-1">Customer email</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ orderDetail.customerEmailAddress | capitalize }}
            </h3>
          </div>
          <div class="order-time mt-6" v-if="orderDetail.createdAt !== null">
            <p class="text-sm text-black md:mb-1">Date/time of creation</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{
                dayjs(orderDetail.createdAt).format("D MMM YYYY  |  hh.mm a")
              }}
            </h3>
          </div>
          <div
            class="payment-method mt-6"
            v-if="orderDetail.customerHandle !== null"
          >
            <p class="text-sm text-black md:mb-1">Customer name</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ orderDetail.customerHandle }}
            </h3>
          </div>
          <div class="payment-method mt-6">
            <p class="text-sm text-black mb-1">
              Amount
            </p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ orderDetail.amountOfOrder | formatMoney }}
            </h3>
          </div>
          <div
            class="payment-method mt-6"
            v-if="orderDetail.paymentPlan !== null"
          >
            <p class="text-sm text-black mb-1">Payment plan</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ orderDetail.paymentPlan }}
            </h3>
          </div>
          <div
            class="payment-method mt-6"
            v-if="orderDetail.feeChargedToMerchant !== null"
          >
            <p class="text-sm text-black mb-1">Fee Charged</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ orderDetail.feeChargedToMerchant | formatMoney }}
            </h3>
          </div>

          <div class="order-time mt-6" v-if="orderDetail.completedAt !== null">
            <p class="text-sm text-black md:mb-1">
              Date/time of payment completion
            </p>
            <h3 class="text-black md:text-lg font-semibold">
              {{
                dayjs(orderDetail.completedAt).format("D MMM YYYY  |  hh.mm a")
              }}
            </h3>
          </div>
        </div>
        <div
          class="
            buttons
            flex flex-col
            md:flex-row
            items-center
            mt-8
            absolute
            bottom-2
          "
          v-if="orderDetail.status === 'PENDING'"
        >
          <Button
            text="Cancel payment link"
            class="md:mr-5 order-last md:order-first block"
            background="bg-lightPurple"
            color="text-brandPurple"
            p="py-2"
            width="w-full md:w-48"
            fontSize="text-sm"
            radius="rounded-3xl"
            fontWeight="font-semibold"
            @click="cancelOrder"
            :loading="cancelLoading"
          />
          <div class="flex justify-center items-center my-4 md:my-0">
            <p class="md:mr-4 order-link w-full">
              {{ orderDetail.paymentLink | truncate(23, "...") }}
            </p>
            <Button
              text="Copy link"
              background="bg-lightPurple"
              color="text-brandPurple"
              class="md:mt-1"
              p="py-2"
              width="w-48"
              fontSize="text-sm"
              radius="rounded-3xl"
              fontWeight="font-semibold"
              @click="copy(orderDetail.paymentLink)"
            />
          </div>
        </div>

        <div
          class="buttons flex items-center mt-8"
          v-if="orderDetail.status === 'CLOSED'"
        >
          <Button
            text="Re-create payment link"
            class="mr-5"
            p="py-3 px-4"
            border="border"
            width="w-full md:w-48"
            :loading="cancelLoading"
            @click="recreateOrder"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  VIEW_ONE_ORDER,
  CANCEL_ORDER,
  CREATE_ORDER
} from "@/utils/api/order.js";
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import Hr from "@/UI/Hr";
import Button from "@/UI/Button";
import copy from "@/mixins/copy";
export default {
  mixins: [copy],
  components: {
    Hr,
    Button
  },
  props: {
    orderDetails: {
      type: Object,
      default: () => {},
      required: true
    },
    params: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  data: () => ({
    loading: false,
    cancelLoading: false,
    orderDetail: {}
  }),
  computed: {
    ...mapState({
      merchantOutletId: state => state?.auth?.user?.merchantOutlet?.id,
      orders: state => state?.order?.orders,
      liveMode: state => state?.auth?.liveMode
    }),
    dayjs() {
      return dayjs;
    }
  },

  beforeMount() {
    this.showOneOrder();
  },

  methods: {
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("order", ["getAllOrders"]),
    showOneOrder() {
      this.loading = true;
      VIEW_ONE_ORDER({
        id: this.orderDetails.id,
        liveMode: this.liveMode
      })
        .then(res => {
          this.orderDetail = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    cancelOrder() {
      this.cancelLoading = true;
      CANCEL_ORDER({
        id: this.orderDetails.id,
        liveMode: this.liveMode
      })
        .then(() => {
          this.cancelLoading = false;
          this.$emit("close");
          this.showAlert({
            display: true,
            type: "success",
            description: `Order Cancelled Successfully`
          });
        })
        .catch(() => {
          this.cancelLoading = false;
        });
    },
    recreateOrder() {
      this.cancelLoading = true;
      const data = {
        merchantOutletId: this.merchantOutletId,
        title: this.orderDetail.title,
        amount: this.orderDetail.amountOfOrder,
        customerHandle: this.orderDetail.customerHandle
      };
      CREATE_ORDER({
        data,
        liveMode: this.liveMode
      })
        .then(() => {
          this.cancelLoading = false;
          this.$emit("close", false);
          this.showAlert({
            display: true,
            type: "success",
            description: `Order Recreated Successfully`
          });
        })
        .catch(() => {
          this.cancelLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.order {
  min-height: 400px;
}
.order-link {
  color: #727272;
}
</style>
